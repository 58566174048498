import { useEffect } from "react";

export const useBlockScroll = () => {
  // block scroll
  useEffect(() => {
    const body = document.body;
    const bodySavedScrollTop = document.documentElement.scrollTop;

    body.setAttribute(
      "style",
      `position: fixed;overflow-y: scroll;width: 100%;top: var(--st, ${
        -document.documentElement.scrollTop + "px"
      });`
    );

    return () => {
      body.setAttribute("style", "");
      document.documentElement.scrollTo({
        top: bodySavedScrollTop,
      });
    };
  });
};
