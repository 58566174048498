import React, { FC } from "react";
import classNames from "classnames";
import { colorByText } from "./helpers/colorByText";
import { Image } from "../Image";

interface Props {
  src?: string | null;
  alt?: string;
  className?: string;
  type?: "square" | "circle";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxxl" | "2xl";
  imgAlt?: string;
}

const sizes = {
  xs: "w-8 h-8 text-xl",
  sm: "w-10 h-10 text-xl",
  md: "w-12 h-12 text-4xl",
  lg: "w-16 h-16 text-4xl",
  xl: "w-20 h-20 text-5xl",
  "2xl": "w-28 h-28 text-6xl",
  xxxl: "w-32 h-32 text-6xl",
};

export const Avatar: FC<Props> = ({
  src,
  alt,
  type = "circle",
  className = "",
  size = "sm",
  imgAlt,
}) => {
  const bgColor = src ? "bg-gray-200" : colorByText(alt || "");
  const rounded = type == "circle" ? "rounded-full" : "rounded-sm";

  const classes = classNames(
    `block object-cover text-xs outline-none`,
    rounded,
    className,
    bgColor,
    sizes[size] || sizes["sm"]
  );

  const innerClasses = classNames(
    "relative items-center justify-center text-white",
    {
      "flex block": !src,
    },
    sizes[size]
  );

  return (
    <div className={classes}>
      <div className={innerClasses}>
        {src && (
          <Image
            src={src}
            alt={imgAlt ?? alt}
            className={classes}
            layout="fill"
            activeCache={true}
          />
        )}
        {!src && alt && <span>{alt[0]}</span>}
      </div>
    </div>
  );
};
