import React, { useEffect, useMemo, useState } from "react";
import { graphql } from "@helpers/graphql";
import { EnviromentType } from "@graphql/types";

export const ENVWarning = () => {
  // find the origin of the current page
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const isWebsiteLocalhost = origin.includes("localhost");
  const isWebsiteQA = origin.includes("travelience.vercel.app");
  const isWebsiteTest = isWebsiteLocalhost || isWebsiteQA;
  const canCallApi = isWebsiteTest;

  const PRODUCTION_DABATABASE_NAME = "gowithguide";

  const [envInfo, setEnvInfo] = useState<EnviromentType | undefined>(undefined);

  useEffect(() => {
    // dont call api in prod
    if (!canCallApi) {
      return;
    }
    graphql.GetEnviromentInfo().then((data) => {
      setEnvInfo(data.getEnviromentInfo);
    });
  }, []);

  const dataFormatted = useMemo(() => {
    let databaseEnvText = "";
    let websiteEnvText = "";
    let bgColor = "bg-primary";
    let textColor = "text-white";

    // databse env
    if (envInfo?.database.name === "triplelights") {
      databaseEnvText = "LOCALHOST DB";
      bgColor = "bg-green-500";
      textColor = "text-white";
    }
    if (
      envInfo?.database.name.includes("testing") ||
      envInfo?.database.name === "gowithguide_testing"
    ) {
      databaseEnvText = "TESTING DB";
      bgColor = "bg-yellow-300";
      textColor = "text-black";
    }
    if (envInfo?.database.name === PRODUCTION_DABATABASE_NAME) {
      databaseEnvText = "PRODUCTION DB";
      bgColor = "bg-primary";
      textColor = "text-white";
    }

    // website env
    if (isWebsiteLocalhost) {
      websiteEnvText = "LOCALHOST ENVIRONMENT";
    }
    if (isWebsiteQA) {
      websiteEnvText = "TESTING ENVIRONMENT";
    }

    return {
      text: databaseEnvText + " IN " + websiteEnvText,
      bgColor,
      textColor,
    };
  }, [envInfo]);

  if (!canCallApi || !envInfo) {
    return null;
  }

  return (
    <div className="mt-8">
      <div className="fixed w-full top-0 z-50">
        <p
          className={`${dataFormatted.bgColor} ${dataFormatted.textColor} text-center p-1 w-full`}
        >
          {dataFormatted.text}
        </p>
      </div>
    </div>
  );
};
