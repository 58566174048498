import classNames from "classnames";
import React, { FC } from "react";

export interface LogoProps {
  full?: boolean;
  variant?: "small";
  className?: string;
  fillColor?: string;
}

export const Logo: FC<LogoProps> = ({
  full,
  fillColor,
  className = "w-48",
  variant,
}) => {
  let width = full ? 192 : 45;

  if (variant) {
    if (variant === "small") {
      width = 250;
    }
  }

  const wrapperClass = classNames("w-48", className);

  if (full) {
    return (
      <img
        src="/img/logo-light-bg.avif"
        alt="GoWithGuide"
        height={38}
        width={192}
        className={wrapperClass}
      />
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox={`0 0 ${width} 42`}
      className={wrapperClass}
      preserveAspectRatio="none"
    >
      <path
        fill="#CF252B"
        d="M30.069 21.086c.514-1.4.871-2.855 1.054-4.382H15.738c.17 1.538.508 2.993.997 4.382h8.547a23.053 23.053 0 01-1.918 3.256 39.614 39.614 0 01-2.85 3.583c-1.17 1.324-2.471 2.676-3.88 4.085-5.767-5.608-9.768-10.325-10.132-16.608-.119-2.036.475-3.755 1.765-5.108 1.803-1.89 4.86-2.974 8.39-2.974.625 0 1.23.046 1.82.112 1.9.212 3.603.748 4.964 1.575.625.38 1.182.817 1.648 1.31 1.088 1.149 1.664 2.558 1.732 4.196h4.433c-.073-2.709-1.046-5.135-2.843-7.084-.032-.035-.059-.074-.092-.108-1.284-1.356-2.955-2.424-4.877-3.17-2.027-.786-4.334-1.213-6.785-1.213-4.742 0-8.973 1.584-11.608 4.347-2.128 2.234-3.156 5.127-2.966 8.369.488 8.407 5.95 14.248 13.04 21.012l1.557 1.486 1.536-1.511c1.85-1.82 3.585-3.572 5.153-5.313a51.072 51.072 0 002.84-3.411c1.66-2.197 2.98-4.438 3.86-6.831z"
      ></path>
      <path
        fill={fillColor || "#C0C0C2"}
        d="M32.46 16.704l-.018.155a20.735 20.735 0 01-.975 4.228h7.367c-1.733 3.645-4.793 7.067-8.647 10.923a96.905 96.905 0 01-3.069-3.104 53.456 53.456 0 01-2.757 3.299l-.084.091c1.366 1.438 2.838 2.881 4.399 4.37l1.56 1.486 1.535-1.51c5.384-5.294 9.818-10.017 11.851-15.555.516-1.4.872-2.855 1.055-4.382H32.46zM29.402 6.553l.63.774c.06-.001.119-.006.18-.006 3.554 0 6.628 1.092 8.432 2.997 1.089 1.149 1.665 2.558 1.732 4.196h4.431c-.071-2.759-1.073-5.227-2.933-7.192-2.637-2.786-6.888-4.384-11.662-4.384-1.712 0-3.35.222-4.876.618 1.539.763 2.867 1.72 3.953 2.866l.113.13z"
      ></path>
    </svg>
  );
};
