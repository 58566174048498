export const getCurrencySymbol = (code: string, locale = "en-US") => {
  const currencyStyled = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: code,
  }).format(0);

  return currencyStyled.replace(/\d|,|\.|\s/g, "").trim();
};

export const getCurrencyFormat = (
  amount: number,
  code: string,
  locale = "en-US"
) => {
  const currencyStyled = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: code || "",
    minimumFractionDigits: 0,
  }).format(amount);

  return currencyStyled;
};

export const getCurrencyFormatNoSymbol = (amount: number, locale = "en-US") => {
  const currencyStyled = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
  }).format(amount);

  return currencyStyled;
};
