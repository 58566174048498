import { Trans } from "@lingui/macro";
import React, { FC } from "react";
import { t } from "@lingui/macro";

export const Wrapper: FC = ({ children }) => {
  return (
    <div
      className={`h-screen`}
      style={{
        minHeight: "500px",
      }}
    >
      <div className="h-screen bg-white text-black">{children}</div>
    </div>
  );
};

export const GlobalNotice = () => {
  return (
    <div className="bg-gray-100 text-center p-3 text-lg">
      <Trans id="global.notification.rebranding">
        <span>
          <b>TripleLights.com</b> has evolved into <b>GoWithGuide.com</b> to
          better align with our global expansion.{` `}
          <a
            href="/triplelights"
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
            className="underline text-link hover:text-link-dark"
          >
            Learn more
          </a>
        </span>
      </Trans>
    </div>
  );
};
