import React, { FC } from "react";
import {
  COOKIE_CURRENCY,
  CURRENCIES,
  CURRENCY_BY_LOCALE,
  LOCALES,
} from "@constants";
import { useCookie } from "@helpers/hooks/useCookie";
import { useRouter } from "next/router";
import cookies from "@helpers/cookies";
import { getCurrencySymbol } from "@helpers/getCurrencyFormat";
import { t, Trans } from "@lingui/macro";
import { useLocale } from "@helpers/locale";
import Modal from "@components/ui/Modal";

const Preferences = () => {
  const router = useRouter();
  const { locale } = useLocale();

  const currentLanguage = locale.toUpperCase();
  const currentCurrency = useCookie(
    COOKIE_CURRENCY,
    CURRENCY_BY_LOCALE[currentLanguage.toLowerCase()]
  );

  const onClick = () => {
    const searchParams = new URLSearchParams(router.asPath);
    searchParams.append("modalPreference", "1");
    const url = `${router.asPath}?${searchParams.toString()}`;
    router.push(url, router.asPath, {
      shallow: true,
    });
  };

  return (
    <div className="whitespace-nowrap cursor-pointer" onClick={onClick}>
      {currentCurrency} | {currentLanguage}
    </div>
  );
};

const Title: FC = ({ children }) => (
  <h3 className="text-base mb-2 text-gray-600 uppercase">{children}</h3>
);

const List: FC = ({ children }) => (
  <ul className="flex flex-wrap">{children}</ul>
);

const Item: FC<{ onClick?: () => void }> = ({ children, ...props }) => (
  <li
    className="w-1/3 md:w-1/4 pb-4 text-link hover:text-link-dark hover:underline cursor-pointer"
    {...props}
  >
    {children}
  </li>
);

export const PreferenceModal = () => {
  const router = useRouter();
  const { setLocale } = useLocale();

  return (
    <Modal title={t({ id: "preferences.modal.title", message: "Preferences" })}>
      <div className="mb-4">
        <Title>
          <Trans id="preferences.modal.languages">LANGUAGES</Trans>
        </Title>
        <List>
          {Object.keys(LOCALES).map((locale) => {
            return (
              <Item key={locale} onClick={() => setLocale(locale)}>
                {LOCALES[locale]}
              </Item>
            );
          })}
        </List>
      </div>
      <div>
        <Title>
          <Trans id="preferences.modal.currencies">CURRENCIES</Trans>
        </Title>
        <List>
          {CURRENCIES.map((currency) => (
            <Item
              key={currency}
              onClick={() => {
                cookies().set(COOKIE_CURRENCY, currency);
                router.reload();
              }}
            >
              {currency} ({getCurrencySymbol(currency)})
            </Item>
          ))}
        </List>
      </div>
    </Modal>
  );
};

export default Preferences;
