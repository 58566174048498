import { CAN_CACHE_IMAGES } from "@constants";
import NextImage, { ImageProps } from "next/image";
import React, { FC, useEffect, useState } from "react";

type IProps = {
  activeCache: boolean;
  fallback?: string;
} & ImageProps;

export const Image: FC<IProps> = ({ activeCache, ...props }) => {
  if (!props.src) {
    return null;
  }

  const [src, setSrc] = useState(props.src);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>();

  // On error, prepare the fallback image url
  useEffect(() => {
    if (error) {
      if (props.fallback) {
        setSrc(props.fallback || "");
      } else {
        setSrc(getFallBackUrlFromAvifUrl());
      }
    }
  }, [error]);

  const getFallBackUrlFromAvifUrl = () => {
    // Image avif url:
    // localhost:3000/images/892A3HJBFHYQpZyhfaOvczWF0Tmm_v0Bj80aSWFPJiM/f:avif/h:480/w:480/plain/gowithguide/cities/2107/86155.jpg

    // Get the s3 path from the source url of gowithguide folder
    let urls = (props?.src).toString().split("plain/gowithguide/");
    if (urls.length > 1) {
      const fallBackUrl = `${
        process.env.NEXT_PUBLIC_S3_BASE_URL
          ? process.env.NEXT_PUBLIC_S3_BASE_URL
          : "https://travelience-cdn.s3.amazonaws.com"
      }/gowithguide/${urls[1]}`;

      return fallBackUrl;
    }

    // Get the s3 path from the source url of planetyze folder
    urls = (props?.src).toString().split("plain/planetyze/");
    if (urls.length > 1) {
      const fallBackUrl = `${
        process.env.NEXT_PUBLIC_S3_BASE_URL
          ? process.env.NEXT_PUBLIC_S3_BASE_URL
          : "https://travelience-cdn.s3.amazonaws.com"
      }/planetyze/${urls[1]}`;

      return fallBackUrl;
    }

    urls = (props?.src).toString().split("plain/gowithguide-testing/");
    if (urls.length > 1) {
      const fallBackUrl = `${
        process.env.NEXT_PUBLIC_S3_BASE_URL
          ? process.env.NEXT_PUBLIC_S3_BASE_URL
          : "https://travelience-cdn.s3.amazonaws.com"
      }/gowithguide-testing/${urls[1]}`;

      return fallBackUrl;
    }

    return props.src || "";
  };

  return (
    <NextImage
      {...props}
      src={src || props.src}
      onError={(e) => {
        setError(e);
      }}
      unoptimized={CAN_CACHE_IMAGES ? !activeCache : true}
    />
  );
};
