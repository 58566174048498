import cookies from "@helpers/cookies";
import React from "react";

export const useCookie = (key: string, defaultValue: string) => {
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    const cookieValue = cookies().get(key);
    setValue(cookieValue || defaultValue);
  }, []);

  return value;
};
